/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";
import { PERMISSIONS, PermissionsService } from "./permissions/permissions.service";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface routes {
  name: String;
  path?: String;
  icon?: String;
  children?: Array<routes>;
  permissions?: string[];
}
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor(private permissions: PermissionsService ) { }
  async getRoutesApplication(): Promise<Array<routes>> {
    const isSuperAdmin = await this.permissions.isSuperAdmin()
    if (isSuperAdmin) {
      return [...menus]
    }
    let permissions = await this.permissions.getPermissions();
    console.log('permissions',permissions);
    
    const menu = await this.buildMenuValidatePermissions(permissions);
    return menu
  }

  buildMenuValidatePermissions(permissions: string[]) {
    let menu: Array<routes> = [
      {
        name: "Home",
        path: "/home",
        icon: "home",
      }
    ]
    menus.forEach((category) => {
      if (category.children) {
        category.children.forEach((subCategory: routes) => {
          if (subCategory.permissions) {
            subCategory.permissions.forEach((permissionInSubCategory) => {
              if (permissions.includes(permissionInSubCategory)) {
                const hasCategory = menu.some((element) => element.name == category.name)
                if (!hasCategory) {
                  menu.push({
                    name: category.name,
                    path: category.path,
                    children: [
                      subCategory
                    ]
                  })
                } else {
                  menu.forEach((element) => {
                    if (element.name == category.name) {
                      if (!element.children) {
                        element.children = []
                      }
                      if (!element.children.some((child) => child.name == subCategory.name))element.children.push(subCategory);
                    }
                  })
                }
              }
            })

          }
        })
      }

    })
    return menu
  }

}

const menus: Array<routes> = [
  {
    name: "Home",
    path: "/home",
    icon: "home",
  },
  {
    name: "Usuarios",
    path: "/",
    children: [
      {
        name: "Aplicaciones",
        path: "/pages/apps",
        icon: "laptop",
        permissions: [
          PERMISSIONS.VIEW_APPS,
        ]
      },
      {
        name: "Empresas",
        path: "/pages/companies",
        icon: "store",
        permissions: [PERMISSIONS.VIEW_COMPANIES]
      },
     
      {
        name: "Usuarios",
        path: "/pages/app-users",
        icon: "account_circle",
        permissions: [
          PERMISSIONS.VIEW_USERS,
        ]
      },
      {
        name: "Cuentas de servicio",
        path: "/pages/service-accounts",
        icon: "receipt_long",
        permissions: [PERMISSIONS.VIEW_SERVICE_ACCOUNT]
      },
    ],
  },
  {
    name: "Pointz",
    path: "/",
    children: [
      {
        name: "Aliados",
        path: "/pages/allies/list",
        icon: "store",
        permissions: [PERMISSIONS.POINTS_COMMERCE_VIEW]
      },
      {
        name: "Canjes",
        path: "/pages/estado-canjes",
        icon: "account_circle",
        permissions: [PERMISSIONS.POINTS_EXCHANGES_VIEW]
      },
      {
        name: "Marcas",
        path: "/pages/brands/list",
        icon: "branding_watermark",
        permissions: [PERMISSIONS.POINTS_BRAND_VIEW]
      },
      {
        name: "Categorias",
        path: "/pages/allies/category/list",
        icon: "category",
        permissions: [PERMISSIONS.POINTS_CATEGORY_VIEW]
      },
      {
        name: "Puntos de venta",
        path: "/pages/sales-point/list",
        icon: "storefront",
        permissions: [PERMISSIONS.POINTS_SALES_LOCATION_VIEW]
      },
    ],
  },
  {
    name: "Banco de puntos",
    path: "/",
    children: [
      {
        name: "Cuentas",
        path: "/pages/points/accounts",
        icon: "people",
        permissions: [
          PERMISSIONS.VIEW_ACCOUNT
        ]
      },
      {
        name: "Tipos de cuentas",
        path: "/pages/points/type-accounts",
        icon: "category",
        permissions: [
          PERMISSIONS.VIEW_ACCOUNT_TYPE
        ]
      },
    ],
  },
  {
    name: "Contenidos",
    path: "/",
    children: [
      {
        name: "CMS",
        path: "/pages/drupal",
        icon: "description",
        permissions: [
          PERMISSIONS.VIEW_CMS
        ]
      },
      {
        name: "CMS Antiguo",
        path: "/pages/cms",
        icon: "description",
        permissions: [
          PERMISSIONS.VIEW_OLD_CMS
        ]
      }
    ]
  },
  {
    name: "Retos, Facturas, Exhibiciones",
    path: "/",
    children: [
      {
        name: "Administrar Respuestas Retos",
        path: "/pages/challenges",
        icon: "radar",
        permissions: [PERMISSIONS.ANSWER_CHALLENGE_VIEW]
      },
      {
        name: "Administrar Respuestas Facturas",
        path: "/pages/invoices",
        icon: "receipt",
        permissions: [PERMISSIONS.ANSWER_INVOICE_VIEW]
      },
      {
        name: "Administrar Respuestas Exhibiciones",
        path: "/pages/exhibitions",
        icon: "receipt",
        permissions: [PERMISSIONS.ANSWER_EXHIBITION_VIEW]
      }
    ]
  },
  {
    name: "Importadores",
    path: "/",
    children: [
      {
        name: "Importadores",
        path: "/pages/imports",
        icon: "cloud_upload",
        permissions: [PERMISSIONS.IMPORTER_BANK_TRANSACTIONS,PERMISSIONS.IMPORTER_CENTRAL_DELIVERIES_UPDATER, PERMISSIONS.IMPORTER_KPI, PERMISSIONS.IMPORTER_CENTRAL_PREINVOICES_UPDATER,PERMISSIONS.IMPORTER_USERS_BY_APPID]
      }
    ]
  },
  {
    name: "Reportes",
    path: "/",
    children: [
      {
        name: "Consolidado Transacciones y Pedidos",
        path: "/pages/consolidado-puntos-pedidos",
        icon: "local_shipping",
        permissions: [PERMISSIONS.REPORT_TRANSACTION_VIEW]
      },
      {
        name: "Consolidado Facturas",
        path: "/pages/invoices-report",
        icon: "receipt",
        permissions: [PERMISSIONS.REPORT_INVOICE_VIEW]
      },
      {
        name: "Consolidado Exhibiciones",
        path: "/pages/exhibitions-report",
        icon: "receipt",
        permissions: [PERMISSIONS.REPORT_EXHIBITION_VIEW]
      },
      {
        name: "Consolidado Retos",
        path: "/pages/challenges-report",
        icon: "radar",
        permissions: [PERMISSIONS.REPORT_CHALLENGE_VIEW]
      }
    ]
  },
  {
    name: "Reconocimientos",
    path: "/",
    children: [
      {
        name: "Listado",
        path: "/pages/recognitions",
        icon: "emoji_events",
        permissions: [PERMISSIONS.RECOGNITION_TYPE_VIEW]
      }
    ]
  },
  {
    name: "Indicadores",
    path: "/",
    children: [
      {
        name: "Categorias",
        path: "/pages/categories/list",
        icon: "table_chart_view",
        permissions: [PERMISSIONS.VIEW_CATEGORIES_KPI]
      },
      {
        name: "Indicadores",
        path: "/pages/indicators/list",
        icon: "insights",
        permissions: [PERMISSIONS.VIEW_KPI]
      },
      {
        name: "Secciones",
        path: "/pages/sections/list",
        icon: "receipt_long",
        permissions: [PERMISSIONS.VIEW_SECTIONS_KPI]
      }
    ]
  },
  {
    name: "Bolsas",
    path: "/",
    children: [
      {
        name: "Listado Bolsas",
        path: "/pages/bags",
        icon: "request_quote",
        permissions: [PERMISSIONS.BAG_VIEW]
      }
    ]
  },
  {
    name: "Exhibiciones",
    path: "/",
    children: [
      {
        name: "Tablas",
        path: "/pages/exhibition/tables",
        icon: "table",
        permissions: [PERMISSIONS.VIEW_EXHIBITION_TABLE]
      },
      {
        name: "Formularios",
        path: "/pages/exhibition/forms",
        icon: "article",
        permissions: [PERMISSIONS.VIEW_EXHIBITION_FORM]
      }
    ]
  }
];

